import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "./videos.style.css"
import bg from "../../../static/images/sub_blank.svg"
import { Button } from "react-bootstrap"

const App = () => {
  const data = useStaticQuery(graphql`
    query Listlinkquery {
      markdownRemark(fileAbsolutePath: { regex: "/tutorial.md/" }) {
        frontmatter {
          subtitle
          title
          defaultTitle
          defaultLink
          description
          thumnails
          features {
            title
            link
          }
        }
      }
    }
  `)
  const Linkdefault = data.markdownRemark.frontmatter.defaultLink
  const TitleVidiodefault = data.markdownRemark.frontmatter.defaultTitle
  const defaultThumnails = data.markdownRemark.frontmatter.thumnails

  const [Link, setLink] = useState("")
  const [title, setTitle] = useState("")
  const [defaultList, setDefaultList] = useState("registrasi")

  // const Registrasi = () => {
  //   setDefaultList("registrasi")
  // }

  // const Setting = () => {
  //   setDefaultList("list")
  // }

  const ButtonLink = param => {
    // console.log(param)
    setLink(param.link)
    setTitle(param.title)
  }

  return (
    <div className="container" id="tutorial">
      <div className="step">
        <div className="step-image">
          <div className="text-title">
            <h3 className="title-text">
              {data.markdownRemark.frontmatter.title}
            </h3>
          </div>
          <iframe
            src={
              Link !== ""
                ? `${Link}?autoplay=1&mute=0fs=0`
                : `${Linkdefault}?autoplay=0&mute=0fs=0`
            }
            title="Nibras Play Tutorial"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen="true"
            className="main-vidio"
          ></iframe>
          <div className="step-text">
            <h3 className="title-text-vidio">
              {title !== "" ? title : TitleVidiodefault}
            </h3>
          </div>
        </div>
        <div className="list-vidio">
          {/* <div className="button-list pb-3 d-flex justify-content-between row">
            <Button
              variant="outline-primary"
              className="button"
              onClick={() => Registrasi()}
            >
              Registrasi
            </Button>
            <Button variant="outline-secondary" onClick={() => Setting()}>
              Pengaturan
            </Button>
          </div> */}
          <div className="subScrol mt-3">
            {/* {defaultList === "registrasi" ? ( */}
            {data.markdownRemark.frontmatter.features.map((v, i) => {
              return (
                <Button
                  variant="none"
                  onClick={() =>
                    ButtonLink({
                      link: v.link,
                      title: v.title !== null ? v.title : TitleVidiodefault,
                    })
                  }
                  className="btn"
                >
                  <div className="sub-vidio row text-center">
                    <img
                      src={defaultThumnails !== "" ? defaultThumnails : bg}
                      className="all-sub-image-static"
                    />
                    <div className="path-subText pl-2">
                      <p className="subText">
                        {v.title !== null ? v.title : TitleVidiodefault}
                      </p>
                    </div>
                  </div>
                </Button>
              )
            })}

            {/* ) : (
              <a href={""}>
                <div className="sub-vidio row text-center">
                  <img src={bg} className="all-sub-image-static" />
                  <div className="path-subText pl-2">
                    <p className="subText">
                      Cara menambahkan alamat Toko di Dashboard Admin
                    </p>
                  </div>
                </div>
              </a>
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
