import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./produk.styles.css"

const Produk = () => {
  const data = useStaticQuery(graphql`
    query Productquery {
      markdownRemark(fileAbsolutePath: { regex: "/product.md/" }) {
        frontmatter {
          features {
            label
            logo
          }
        }
      }
    }
  `)

  const dataBrand = data.markdownRemark.frontmatter.features.map(rev => {
    return {
      label: rev.label,
      logo: rev.logo,
    }
  })

  return (
    <div id="produk">
      <div className="container">
        <div className="patch__brand">
          <div className="produk-title">
            <h2></h2>
          </div>
          <div className="brand-row">
            <div className="brand-col">
              <img src={dataBrand[0].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[1].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[2].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[3].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[4].logo} className="logo__brand" />
            </div>
          </div>
          <div className="brand-row_2">
            <div className="brand-col">
              <img src={dataBrand[5].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[6].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[7].logo} className="logo__brand" />
            </div>
            <div className="brand-col">
              <img src={dataBrand[8].logo} className="logo__brand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Produk
