import React from "react"
import SEO from "../components/seo"
import Header from "../components/header/header.component"
import Footer from "../components/footer/footer.component"
import Hero from "../components/hero/hero.component"
import Peluang from "../components/peluang/peluang.component"
import Platform from "../components/platform/platform.component"
import Tentang from "../components/tentang/tentang.component"
import App from "../components/videos/videos.component.jsx"
import Procedures from "../components/cara/cara.component"
import Brand from "../components/produk/produk.component"
import Module from "../components/module/module.component"
import Banner from "../components/banner/banner.component"

// import Platform from "../components/platform/platform.components"
// import Cara from "../components/cara/cara.component"

const IndexPage = () => {
  return (
    <div>
      <SEO
        title="Nibrasplay"
        url="https://nibrasplay.co.id/"
        image="https://nibrasplay.co.id/images/favicon.png"
      />
      <Header />
      <Banner/>
      <Hero />
      <Tentang />
      <Brand />
      <Platform />
      <Procedures />
      <App />
      <Module />  
      <Peluang />
      <Footer />
    </div>
  )
}

export default IndexPage
