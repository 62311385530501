import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "./module.styles.css"
import { Col, Container, Row } from "react-bootstrap"
import Download from "../../../static/images/download.svg.svg"

const Module = () => {
  const data = useStaticQuery(graphql`
    query ModuleQuery {
      markdownRemark(fileAbsolutePath: { regex: "/pricing.md/" }) {
        frontmatter {
          title
          hide1
          hide2
          hide3
          text
          default_modul
          reviews {
            name
            modul
          }
        }
      }
    }
  `)

  const title = data.markdownRemark.frontmatter.title
  const text_download = data.markdownRemark.frontmatter.text
  const default_link = data.markdownRemark.frontmatter.default_modul
  const hide_1 = data.markdownRemark.frontmatter.hide1
  const hide_2 = data.markdownRemark.frontmatter.hide2
  const hide_3 = data.markdownRemark.frontmatter.hide3

  const dataModule = data.markdownRemark.frontmatter.reviews.map((e, v) => {
    return {
      nama: e?.name,
      module: e?.modul,
    }
  })

  // console.log(data)

  return (
    <Container>
      <div className="container mr-4" id="modul">
        <div className="module">
          <div className="hero-text">
            <h1>{title}</h1>
          </div>
        </div>
        {/* module 1 - 2 */}
        <Row className="col mt-3">
          <Col>
            <Row>
              <Col sm={2}>
                <img src={Download} className="image" />
              </Col>
              <Col sm={10}>
                <h5>{dataModule[0].nama}</h5>
                <a
                  download
                  href={
                    dataModule[0]?.module !== "not"
                      ? dataModule[0]?.module
                      : default_link
                  }
                  className="download"
                  target="_blank"
                >
                  {text_download}
                </a>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col sm={2}>
                <img src={Download} className="image" />
              </Col>
              <Col sm={10}>
                <h5>{dataModule[1].nama}</h5>
                <a
                  download
                  href={
                    dataModule[1]?.module !== "not"
                      ? dataModule[1]?.module
                      : default_link
                  }
                  className="download"
                  target="_blank"
                >
                  {text_download}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        {hide_1 !== "hide" ? (
          <>
            {/* module 3 - 4 */}
            <Row className="col mt-5">
              <Col>
                <Row>
                  <Col sm={2}>
                    <img src={Download} className="image" />
                  </Col>
                  <Col sm={10}>
                    <h5>{dataModule[2].nama}</h5>
                    <a
                      download
                      href={
                        dataModule[2]?.module !== "not"
                          ? dataModule[2]?.module
                          : default_link
                      }
                      className="download"
                      target="_blank"
                    >
                      {text_download}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col sm={2}>
                    <img src={Download} className="image" />
                  </Col>
                  <Col sm={10}>
                    <h5>{dataModule[3].nama}</h5>
                    <a
                      download
                      href={
                        dataModule[3]?.module !== "hide"
                          ? dataModule[3]?.module
                          : default_link
                      }
                      className="download"
                      target="_blank"
                    >
                      {text_download}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
        {hide_2 !== "hide" ? (
          <>
            {/* module 5 - 6 */}
            <Row className="col mt-5">
              <Col>
                <Row>
                  <Col sm={2}>
                    <img src={Download} className="image" />
                  </Col>
                  <Col sm={10}>
                    <h5>{dataModule[4].nama}</h5>
                    <a
                      download
                      href={
                        dataModule[4]?.module !== "hide"
                          ? dataModule[4]?.module
                          : default_link
                      }
                      className="download"
                      target="_blank"
                    >
                      {text_download}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col sm={2}>
                    <img src={Download} className="image" />
                  </Col>
                  <Col sm={10}>
                    <h5>{dataModule[5].nama}</h5>
                    <a
                      download
                      href={
                        dataModule[5]?.module !== "hide"
                          ? dataModule[5]?.module
                          : default_link
                      }
                      className="download"
                      target="_blank"
                    >
                      {text_download}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
        {hide_3 !== "hide" ? (
          <>
            {/* module 7 - 8 */}
            <Row className="col mt-5 mb-5">
              <Col>
                <Row>
                  <Col sm={2}>
                    <img src={Download} className="image" />
                  </Col>
                  <Col sm={10}>
                    <h5>{dataModule[6].nama}</h5>
                    <a
                      download
                      href={
                        dataModule[6]?.module !== "hide"
                          ? dataModule[6]?.module
                          : default_link
                      }
                      className="download"
                      target="_blank"
                    >
                      {text_download}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col sm={2}>
                    <img src={Download} className="image" />
                  </Col>
                  <Col sm={10}>
                    <h5>{dataModule[7].nama}</h5>
                    <a
                      download
                      href={
                        dataModule[7]?.module !== "hide"
                          ? dataModule[7]?.module
                          : default_link
                      }
                      className="download"
                      target="_blank"
                    >
                      {text_download}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    </Container>
  )
}

export default Module
