import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import nexticon from "../../../static/images/next.png"
import provicon from "../../../static/images/prove.png"
import "bootstrap/dist/css/bootstrap.min.css"
import "./platform.scss"
import Carousel from "react-bootstrap/Carousel"

interface MovieItem {
  title: string
  rating: number
  synopsis: string
  image: string
}

const App: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Reviewquery {
      markdownRemark(fileAbsolutePath: { regex: "/reviews.md/" }) {
        frontmatter {
          reviews {
            name
            job
            review
            pic
          }
        }
      }
    }
  `)

  const movies = data.markdownRemark.frontmatter.reviews.map(rev => {
    return {
      image: rev.pic,
      name: rev.name,
      job: rev.job,
      synopsis: rev.review,
    }
  })
  

  const genArray = c => Array.from(Array(c).keys())
  const initial = genArray(movies.length)

  const [slides, setSlides] = React.useState<number[]>(initial)
  const big = slides[2] // mengatur text informasi di bawah slice 
  // const { rating } = movies[big]
  // const roundedUp = Math.ceil(rating)
  // const pct = movies[big].rating * 10
  
  // console.log('==>',slides);
  
  const rotate = () => {
    setSlides(prev => [...prev.slice(1), prev[0]])
  }

  const prov = () => {
    setSlides(prev => [...prev.slice(1), prev[0]])
  }
  
  return (
    <>
      <div className="app" id="fitur">
        <div className="app__inner">
          <h1 className="text__title_app">Tingkatkan Penjualan Hanya Dengan Satu Platform</h1>
          <div className="slider">
            <ul className="slider__list">
              {slides.map((idx, i) => (
                <li
                  key={idx}
                  style={{
                    transform: `translateX(${
                      i === 0 ? 34.5 : 34 + 15.2 * i
                    }rem)`,
                  }}
                  className="slider__item"
                >
                  <img src={movies[idx].image} className="slider__item-img" />
                  <p className="sub__text">{movies[idx].name}</p>
                </li>
              ))}
            </ul>
            <div className="text__slider-btn" onClick={rotate}>
              <img src={nexticon} />
            </div>
            <div className="text__slider-btn-prov" onClick={prov}>
              <img src={provicon} />
            </div>
          </div>
          
          <div className="text__group">
              <p className="text__synopsis">{movies[big].synopsis}</p>
              <br/>
              <div className="button-hero">
                <Link to={"https://app.nibrasplay.co.id/"}>
                  <button>Mulai Jualan</button>
                </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <Carousel customVariant variant="dark">
          {data.markdownRemark.frontmatter.reviews.map(rev => (
            <Carousel.Item>
              <div className="patch__mobile d-blok">
                <img className="rounded mx-auto" src={rev.pic} alt="First slide" />
              <Carousel.Caption>
                <p className="text__name">{rev.name}</p>
                <p className="text__job">{movies[big].synopsis}</p>
              </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default App
