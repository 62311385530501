import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./about.styles.css"
import bg from "../../../static/images/about_home.png"

const Tentang = () => {
  const data = useStaticQuery(graphql`
    query aboutQuery {
      markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
        frontmatter {
          description1
          description2
        }
      }
    }
  `)

  return (
    <div className="bg-container">
      <div className="container" id="tentang">
        <div className="about">
          <div className="hero-image-about">
            <img src={bg} loading="lazy" alt="hero" />
          </div>

          <div className="about-text">
            <h1>Tentang Nibrasplay</h1>
            <p>{data.markdownRemark.frontmatter.description1}</p>
            <p>{data.markdownRemark.frontmatter.description2}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tentang
