import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "./banner.styles.css"
// import bg from "../../../static/images/hero.png"

const Banner = () => {
  const [images, setImages] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)


  useEffect(() => {
    fetch(`http://nbrscorp.co.id/list-banner`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }).then(response => response.json())
    .then(res => {
      setImages(res)
    })
    .catch(e => {
      const newError = {};
      console.log(e);
    });
    }, [])

  useEffect(() => {
    const slideTimer = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % images.length)
    }, 3000) // Change the interval (in milliseconds) as needed

    return () => {
      clearInterval(slideTimer)
    }
  }, [currentSlide, images])

  return (
    <div className="Banner">
      <img width={'100%'} src={images[currentSlide]?.url} alt="Banner" />
    </div>
  )
}

export default Banner
