import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import bg from "../../../static/images/cara.svg"
import "./cara.style.css"

const WaysJobs = () => {
  const data = useStaticQuery(graphql`
    query Caraquery {
      markdownRemark(fileAbsolutePath: { regex: "/cara.md/" }) {
        frontmatter {
          title
          description
          features {
            features
          }
          value1
          value2
          value3
          label1
          label2
          label3
        }
      }
    }
  `)

  return (
    <div className="bg-container">
      <div className="container" id="kerja">
        <div className="cara-container">
          <div className="cara-text">
            <h1>{data.markdownRemark.frontmatter.title}</h1>
            <ul className="cara">
              {data.markdownRemark.frontmatter.features.map(f => (
                <li>
                  <span>{f.features}</span>
                </li>
              ))}
            </ul>
            <div className="produk-row">
              <div className="produk-col">
                <span className="produk-num produk-color">
                  {data.markdownRemark.frontmatter.value1}
                </span>
                <span className="produk-label">
                  {data.markdownRemark.frontmatter.label1}
                </span>
              </div>
              <div className="produk-col">
                <span className="produk-num">
                  {data.markdownRemark.frontmatter.value2}
                </span>
                <span className="produk-label">
                  {data.markdownRemark.frontmatter.label2}
                </span>
              </div>
              <div className="produk-col">
                <span className="produk-num">
                  {data.markdownRemark.frontmatter.value3}
                </span>
                <span className="produk-label">
                  {data.markdownRemark.frontmatter.label3}
                </span>
              </div>
            </div>
          </div>

          <div className="cara-image_">
            <img src={bg} loading="lazy" alt="hero" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WaysJobs
