import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "./hero.styles.css"
import bg from "../../../static/images/hero.png"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query Heroquery {
      markdownRemark(fileAbsolutePath: { regex: "/hero.md/" }) {
        frontmatter {
          subtitle
          title
          description
          herolink
          herotext
        }
      }
    }
  `)

  return (
    <div className="container" id="home">
      <div className="hero">
        <div className="hero-text">
          {/* <h6>{data.markdownRemark.frontmatter.subtitle}</h6> */}
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          <p>{data.markdownRemark.frontmatter.description}</p>
          <div className="button-hero">
            <Link to={data.markdownRemark.frontmatter.herolink}>
              <button>{data.markdownRemark.frontmatter.herotext}</button>
            </Link>
          </div>
        </div>
        <div className="hero-image">
          <img src={bg} loading="lazy" alt="hero" />
        </div>
      </div>
    </div>
  )
}

export default Hero
