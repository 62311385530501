import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import "./peluang.styles.css"

const Peluang = () => {
  const data = useStaticQuery(graphql`
    query Peluangquery {
      markdownRemark(fileAbsolutePath: { regex: "/peluang.md/" }) {
        frontmatter {
          title
          description1
          description2
          link
          buttontext
        }
      }
    }
  `)

  return (
    <div className="background">
      <div className="container">
        <div className="peluang">
          <div className="peluang-image">
            <img src="/images/peluang.svg" alt="phone" />
          </div>
          <div className="peluang-text">
            <h2>{data.markdownRemark.frontmatter.title}</h2>
            <p>{data.markdownRemark.frontmatter.description1}</p>
            <p>{data.markdownRemark.frontmatter.description2}</p>
            <Link to={data.markdownRemark.frontmatter.link}>
              <div className="peluang-button">
                <button>{data.markdownRemark.frontmatter.buttontext}</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Peluang
